require('./bootstrap');
import 'jquery-ui/ui/widgets/datepicker.js';

// fix bootstrap modal + datepicker bug:
// https://stackoverflow.com/questions/21059598/implementing-jquery-datepicker-in-bootstrap-modal/21088713#21088713
//var enforceModalFocusFn = $.fn.modal.Constructor.prototype._enforceFocus;
$.fn.modal.Constructor.prototype._enforceFocus = function() {};

$.ajaxSetup({
  headers: {
    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
  }
});

$("#filter_start_date").on('change', function(e, datestr) {
  setTimeout(function() {
    window.location.search = $.query.set("filterStartDate", datestr);
  }, 0);
});

var defaultDate = new Date();
defaultDate.setFullYear(defaultDate.getFullYear() - 18);

$(".datepicker").each(function() {
  $(this).attr('autocomplete', 'off');
});

var birthdayOptions = {
  showOn:'focus',
  dateFormat:'dd.mm.yy',
  changeMonth: true,
  changeYear: true,
  yearRange: "-100:+0",
  defaultDate: defaultDate,
  beforeShow: function(textbox, instance) {
    haschanged = $(textbox).val();
  },
  onSelect: function(datestr,inst) {
    var jqinst = $(inst.input);
    if(jqinst.hasClass('datepicker-autocomplete')) {
      fillout(datestr, inst, jqinst);
      jqinst.closest("form").find("#view-date").focus();
    }
    if(jqinst.hasClass('trigger')) {
      jqinst.trigger("change", datestr);
    }
  },
  duration:''
};

var globalOptions = {
  showOn:'focus',
  dateFormat:'dd.mm.yy',
  beforeShow: function(textbox, instance) {
    haschanged = $(textbox).val();
  },
  onSelect: function(datestr,inst) {
    var jqinst = $(inst.input);
    if(jqinst.hasClass('datepicker-autocomplete')) {
      fillout(datestr, inst, jqinst);
      jqinst.closest("form").find("#view-date").focus();
    }
    if(jqinst.hasClass('trigger')) {
      jqinst.trigger("change", datestr);
    }
  },
  duration:''
};

$(".datepicker.birthday").datepicker(birthdayOptions);
$(".datepicker").not(".birthday").datepicker(globalOptions);

var haschanged;

function fillout(datestr, inst, jqinst) {
  var sdatum = datestr; // kurs_form.kurs_sdatum.value;
  if(haschanged != sdatum) {
    var wochentage = new Array('Sonntag','Montag','Dienstag','Mittwoch','Donnerstag','Freitag','Samstag');
    var monate = new Array('Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember');

    var sdatum_array = sdatum.split('.');
    var sdatum_date = new Date();
    var year = parseInt(sdatum_array[2]);
    if(year < 2000)
      year += 2000;

    sdatum_date.setFullYear(year,parseInt(sdatum_array[1])-1,parseInt(sdatum_array[0]));

    var tag = sdatum_date.getDate();
    if(tag < 10) tag = '0'+tag;
    tag += './';

    var snddatum_date = new Date();
    snddatum_date.setFullYear(sdatum_date.getFullYear(),sdatum_date.getMonth(),sdatum_date.getDate()+1);

    var tag2 = snddatum_date.getDate();
    if(tag2 < 10) tag2 = '0'+tag2;
    tag += tag2+'. ';

    tag += monate[snddatum_date.getMonth()] + ' ' + snddatum_date.getFullYear();

    jqinst.closest("form").find('#view-date').val(tag);

    var zeit1 = "";
    var zeit2 = "";
    if(sdatum_date.getDay() == 5) // freitag 1900-2100
    {
      zeit1 = "19.00 - 21.00";
      zeit2 = "08.00 - 17.00";
    }
    else if(sdatum_date.getDay() == 6) // samstag 1700-1930
    {
      zeit1 = "18.00 - 20.00";
      zeit2 = "08.00 - 17.00";
    }
    else
    {
      zeit1 = "xx.xx - xx.xx";
      zeit2 = "xx.xx - xx.xx";
    }

    var zeit = wochentage[sdatum_date.getDay()] + ': ' + zeit1 + ' / '+wochentage[snddatum_date.getDay()] + ': ' + zeit2;

    jqinst.closest("form").find('#view-time').val(zeit);
  }
}

$(".courses").click(function(e) {
  var btn = $(this);
  var course_id = btn.attr("data-id");

  $("#myModal").modal("show");
  $("#myModal input:text, #myModal textarea").first().focus();

  $("#myModal #save").html('Anmelden');

  var form =
    $("#myModal form")
      .attr("method", "POST")
      .attr("action", "/api/member");

  resetForm(form);

  $("#myModal #course-id").val(course_id);
  $("#myModal #member-id").val(-1);
});


$("form.auto").submit(function(e) {
  handleForm(e, $(this));
});

function resetValidation(form) {
  form.find(":input")
    .removeClass("is-valid")
    .removeClass("is-invalid")
}

function resetForm(form) {
  resetValidation(form);
  form.get(0).reset();
}

function handleForm(e, form) {
  e.preventDefault();

  var method = form.attr("method");
  var url = form.attr("action");

  $.ajax({
    type: method,
    url: url,
    data: form.serialize(),
    success: function(data) {
      toastr.success('Erfolgreich gespeichert!');
      form.closest('div.modal').modal("hide");
      // TODO reload.. evt mit erfolgmeldung nachher
      try {
        // TODO check whether new member..
        gtag_report_conversion();
      } catch(error) {
        // ignore google errors..
      }
    },
    error: function(data) {
      toastrError("Fehler beim speichern!", data);
      markErrorsInForm(form, data);
    }
  });
}

function toastrError(title, data) {
  var message = "";
  console.log(data);
  if('responseJSON' in data && 'message' in data.responseJSON) {
    message = data.responseJSON.message;
  }
  toastr.error(message, title);
}

function markErrorsInForm(form, data) {
  form
    .find(":input")
    .addClass("is-valid")
    .removeClass("is-invalid")
    .next(".invalid-feedback")
    .hide();

  var errors = data.responseJSON['errors'];
  for (var key in errors) {
    if (Object.prototype.hasOwnProperty.call(errors, key)) {
      form
        .find("[name='" + key + "']")
        .removeClass("is-valid")
        .addClass("is-invalid")
        .next(".invalid-feedback")
        .show()
        .text(errors[key][0]);
    }
  }
}

function handleLoad(url) {
  return $.ajax({
    type: "GET",
    url: url
  });
}

$(".add-action").click(function() {
  var link = $(this);

  var url = link.attr("data-url");
  var modalId = link.attr("data-domid");
  var primaryId = link.attr("data-primaryid");
  var setId = link.attr("data-setid");

  $(modalId + " #save").html('Speichern');

  var form = $(modalId + " form")
    .attr("method", "POST")
    .attr("action", url);

  resetForm(form);

  $(modalId).modal("show");
  $(modalId + " " + primaryId).val(-1);

  handleSetId(modalId, setId);
});

function handleSetId(modalId, setId) {
  var kv = setId.split(':');
  $(modalId + " #" + kv[0]).val(kv[1]);
}

$(".edit-action").click(function() {
  var link = $(this);

  var id = link.attr("data-id");
  var text = link.attr("data-text");
  var url = link.attr("data-url");
  var modalId = link.attr("data-domid");

  $(modalId + " #save").html('Speichern');

  handleLoad(url + id)
    .done(function(data) {
      var form = $(modalId + " form");
      resetForm(form);
      form
        .attr("method", "PUT")
        .attr("action", url + id)
      //https://api.jquery.com/jQuery.param/
        .deserialize($.param(data));
      $(modalId).modal("show");
    })
    .fail(function(data) {
      toastrError("Konnte den " + text + " nicht laden!", data);
    });
});

$(".delete-action").click(function() {
  var link = $(this);
  var text = link.attr("data-text");

  if(!confirm(text + " wirklich löschen?")) {
    return;
  }

  var id = link.attr("data-id");
  var url = link.attr("data-url");

  $.ajax({
    type: "DELETE",
    url: url + id,
    success: function() {
      window.location.reload(true);
    },
    error: function(data) {
      toastrError(text + " konnte nicht gelöscht werden!", data);
    }
  });
});

$(".status-action").click(function() {
  var link = $(this);
  var text = link.attr("data-text");

  var id = link.attr("data-id");
  var url = link.attr("data-url");
  var confirmText = link.attr("data-confirm-text");

  if(link.hasClass('confirm-action') && !confirm(confirmText)) {
    return;
  }

  $.ajax({
    type: "PUT",
    url: url.replace('-id-', id),
    success: function(status) {
      // TODO change class status... and don't reload
      window.location.reload(true);
    },
    error: function(data) {
      toastrError("Status " + text + " konnte nicht angepasst werden!", data);
    }
  });
});

